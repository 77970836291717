import * as React from 'react';
import {ConsumerWrapper, IndustriesWrapper} from './Styled';
import NumberedSelector from './NumberedSelector';

const Educational = () => {
  return (
    <IndustriesWrapper>
      <div className="image">
        <img
          className=""
          alt="Educational Solutions"
          src="/images/industries/retail-crm-main.jpg"
        />
      </div>
      <div className="wrapper">
        <h1>Retail and CRM</h1>
        <div className="cols">
          <img
            className=""
            alt="Retail CRM"
            src="/images/industries/retail-crm.jpg"
          />
          <p>
            Zegates, renowned for its expertise in CRM and retail management
            software, has recently unveiled an innovative platform named
            Netlise.
            <br />
            <br />
            Netlise is designed to deliver integrated retail solutions and
            robust CRM functionalities, tailored to meet the dynamic needs of
            modern businesses. The platform offers a comprehensive suite of
            tools to enhance customer relationship management, improve
            operational efficiency, and drive sales growth. With features that
            streamline retail operations, Netlise empowers businesses to
            optimize their processes, engage customers more effectively, and
            ultimately, achieve greater success in a competitive market.
          </p>
        </div>

        <NumberedSelector data={data}>
          <h3>
            Partner with us with for intelligent retail market development
          </h3>
          <div className="wrapper"></div>
        </NumberedSelector>
        <div className="section">
          <div>
            <div>
              <p>ENHANCE YOUR RETAIL MARKETS</p>
              <h2>Zegates Product Engineering</h2>
            </div>
            <p>
              Use negates expertise in product engineering to enhance your
              consumer market goals by innovating your existing business or
              starting new one. We can bring right tech to your door step with
              minimal time to develop. And it will be always data driven and we
              will be using KPIs to manage and grow your business rapidly
            </p>
          </div>
          <div>
            <img src="/images/home/consumer-zegates.jpg" />
          </div>
        </div>
        <div className="section">
          <div>
            <img src="/images/home/consumer-netlise.jpg" />
          </div>
          <div>
            <h2>SaaS Solutions</h2>
            <p>
              Searching for an all in one product suite? We created Netlise to
              make your world easier to manage the products and achieve consumer
              market goals quickly. Netlise offers streamlined functionality for
              E-commerce, Point of sale and CRM in a SaaS platform with offline
              and online capabilities.
            </p>
          </div>
        </div>
      </div>
    </IndustriesWrapper>
  );
};

const data = [
  {
    title: 'Enhanced Customer Experience',
    content: (
      <>
        <h2>Personalization</h2>
        <p>
          CRM software in retail helps businesses collect and analyze customer
          data, enabling personalized marketing and tailored customer
          interactions. By understanding individual customer preferences and
          purchase history, retailers can offer targeted promotions, product
          recommendations, and personalized communications.
        </p>
        <h2>Omnichannel Experience</h2>
        <p>
          Retail software integrates various sales channels (online, in-store,
          mobile) to provide a seamless omnichannel experience. Customers can
          start their shopping journey on one platform and complete it on
          another without losing continuity, enhancing overall satisfaction and
          loyalty.
        </p>
      </>
    ),
  },
  {
    title: 'Operational Efficiency',
    content: (
      <>
        <h2>Inventory Management</h2>
        <p>
          Retail software includes advanced inventory management systems that
          track stock levels in real-time, predict demand, and automate
          reordering processes. This reduces the risk of overstocking or
          stockouts, optimizing inventory levels and reducing costs.
        </p>
        <h2>Sales Analytics and Reporting</h2>
        <p>
          CRM and retail software provide powerful analytics tools that help
          businesses gain insights into sales trends, customer behavior, and
          market demands. These insights allow retailers to make data-driven
          decisions, improve sales strategies, and enhance operational
          efficiency.
        </p>
      </>
    ),
  },
  {
    title: 'Improved Customer Engagement and Retention',
    content: (
      <>
        <h2>Loyalty Programs</h2>
        <p>
          CRM software facilitates the creation and management of loyalty
          programs, encouraging repeat purchases and building long-term customer
          relationships. Automated rewards, discounts, and personalized offers
          help keep customers engaged and motivated to return.
        </p>
        <h2>Automated Communication</h2>
        <p>
          Retailers use CRM software to automate customer communications,
          including follow-up emails, feedback requests, and promotional
          messages. This ensures consistent and timely interaction with
          customers, improving engagement and fostering loyalty.
        </p>
      </>
    ),
  },
];

export default Educational;
